<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-12 20:36:16
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-30 15:00:13
 * @FilePath: /siana_admin/src/views/system/pay/index.vue
-->
<template>
  <el-card>
    <div slot="header">
      <h2>支付配置
      </h2>
    </div>
    <el-form ref="ruleForm"
      :model="form"
      :rules="rules"
      label-width="180px"
      :label-position="phone?'top':'right'"
      class="demo-ruleForm">
      <el-form-item
        label="支付类型"
        prop="payType">
        <el-select
          v-model="form.payType"
          :disabled="disabled"
          style="width:100%;max-width: 600px;"
          placeholder="请选择支付类型">
          <el-option
            label="易支付"
            :value="0" />
          <el-option
            label="卡密"
            :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="易支付pid"
        prop="pid">
        <el-input
          v-model="form.pid"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="易支付secretKey"
        prop="secretKey">
        <el-input
          v-model="form.secretKey"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>

      <el-form-item
        label="易支付支付请求地址"
        prop="submitUrl">
        <el-input
          v-model="form.submitUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        label="易支付订单查询Api"
        prop="apiUrl">
        <el-input
          v-model="form.apiUrl"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        v-if="!disabled">
        <el-button
          type="primary"
          @click="handleSubmit()">保存</el-button>
        <el-button
          @click="handleCancel()">取消</el-button>
      </el-form-item>
      <el-form-item v-else>
        <el-button
          type="primary"
          @click="disabled = !disabled">去编辑</el-button>

      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      phone: false,
      form: {
        apiUrl: '',
        payType: 0,
        pid: '',
        secretKey: '',
        submitUrl: ''
      },
      disabled: true,
      rules: {
        apiUrl: [{ required: false, message: '请输入', trigger: 'blur' }],
        pid: [{ required: false, message: '请输入', trigger: 'blur' }],
        secretKey: [{ required: false, message: '请输入', trigger: 'blur' }],
        submitUrl: [{ required: false, message: '请输入', trigger: 'blur' }],
        payType: [{ required: false, message: '请选择', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    this.getData()
  },
  methods: {
    getData() {
      this.$https('PAYSETTING', {}).then(res => {
        console.log(res)
        this.form = {
          id: res.data.id,
          apiUrl: res.data.apiUrl,
          payType: res.data.payType,
          pid: res.data.pid,
          secretKey: res.data.secretKey,
          submitUrl: res.data.submitUrl
        }
      })
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$https('PAYSETTINGEDIT', this.form).then(res => {
            if (res.status == 200) {
              this.$message.success('编辑成功！')
              this.disabled = true
            }
          })
        }
      })
    },
    handleCancel() {
      this.form = {
        id: '',
        apiUrl: '',
        payType: 0,
        pid: '',
        secretKey: '',
        submitUrl: ''
      }
      this.getData()
      this.disabled = true
    }
  }
}
</script>

<style>
</style>
